.hamburger {
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 10px 0 15px 15px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity, filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent
}

.hamburger.is-active .hamburger-inner,.hamburger.is-active .hamburger-inner:after,.hamburger.is-active .hamburger-inner:before {
    background-color: #fff
}

.hamburger-box {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 16px
}

.hamburger-inner {
    top: 50%;
    display: block;
    margin-top: -2px
}

.hamburger-inner,.hamburger-inner:after,.hamburger-inner:before {
    position: absolute;
    width: 40px;
    height: 4px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: #fff
}

.hamburger-inner:after,.hamburger-inner:before {
    display: block;
    content: ""
}

.hamburger-inner:before {
    top: -10px
}

.hamburger-inner:after {
    bottom: -10px
}


.hamburger--slider .hamburger-inner {
    top: 2px
}

.hamburger--slider .hamburger-inner:before {
    top: 10px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform,opacity
}

.hamburger--slider .hamburger-inner:after {
    top: 20px
}

.hamburger--slider.is-active .hamburger-inner {
    transform: translate3d(0,10px,0) rotate(45deg)
}

.hamburger--slider.is-active .hamburger-inner:before {
    transform: rotate(-45deg) translate3d(-5.71429px,-6px,0);
    opacity: 0
}

.hamburger--slider.is-active .hamburger-inner:after {
    transform: translate3d(0,-20px,0) rotate(-90deg)
}

.hamburger--slider-r .hamburger-inner {
    top: 2px
}

.hamburger--slider-r .hamburger-inner:before {
    top: 10px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform,opacity
}

.hamburger--slider-r .hamburger-inner:after {
    top: 20px
}

.hamburger--slider-r.is-active .hamburger-inner {
    transform: translate3d(0,10px,0) rotate(-45deg)
}

.hamburger--slider-r.is-active .hamburger-inner:before {
    transform: rotate(45deg) translate3d(5.71429px,-6px,0);
    opacity: 0
}

.hamburger--slider-r.is-active .hamburger-inner:after {
    transform: translate3d(0,-20px,0) rotate(90deg)
}

.show-menu {
    visibility: visible;
}