body {
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
}

h1,h2,h3,h4,h5 {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}

h1 {
  margin-bottom: 1.5em;
}

h2 {
  font-size: 2em;
}

a {
  opacity: 1 !important;
}


button:hover, button:focus {
  opacity: 1;
  outline: none;
}

p:last-of-type {
  margin-bottom: 2em;
}

a {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease-in;
  border: none;
  outline: none;
  box-shadow: none;
}

a:hover,
a:active,
a:focus {
    opacity: 1;
}

a.active {
  opacity: 0.8;
}