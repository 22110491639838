body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hamburger {
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 10px 0 15px 15px;
    cursor: pointer;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transition-duration: .15s;
            transition-duration: .15s;
    -webkit-transition-property: opacity, -webkit-filter;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent
}

.hamburger.is-active .hamburger-inner,.hamburger.is-active .hamburger-inner:after,.hamburger.is-active .hamburger-inner:before {
    background-color: #fff
}

.hamburger-box {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 16px
}

.hamburger-inner {
    top: 50%;
    display: block;
    margin-top: -2px
}

.hamburger-inner,.hamburger-inner:after,.hamburger-inner:before {
    position: absolute;
    width: 40px;
    height: 4px;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: .15s;
            transition-duration: .15s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    border-radius: 4px;
    background-color: #fff
}

.hamburger-inner:after,.hamburger-inner:before {
    display: block;
    content: ""
}

.hamburger-inner:before {
    top: -10px
}

.hamburger-inner:after {
    bottom: -10px
}


.hamburger--slider .hamburger-inner {
    top: 2px
}

.hamburger--slider .hamburger-inner:before {
    top: 10px;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: .15s;
            transition-duration: .15s;
    -webkit-transition-property: opacity,-webkit-transform;
    transition-property: opacity,-webkit-transform;
    transition-property: transform,opacity;
    transition-property: transform,opacity,-webkit-transform
}

.hamburger--slider .hamburger-inner:after {
    top: 20px
}

.hamburger--slider.is-active .hamburger-inner {
    -webkit-transform: translate3d(0,10px,0) rotate(45deg);
            transform: translate3d(0,10px,0) rotate(45deg)
}

.hamburger--slider.is-active .hamburger-inner:before {
    -webkit-transform: rotate(-45deg) translate3d(-5.71429px,-6px,0);
            transform: rotate(-45deg) translate3d(-5.71429px,-6px,0);
    opacity: 0
}

.hamburger--slider.is-active .hamburger-inner:after {
    -webkit-transform: translate3d(0,-20px,0) rotate(-90deg);
            transform: translate3d(0,-20px,0) rotate(-90deg)
}

.hamburger--slider-r .hamburger-inner {
    top: 2px
}

.hamburger--slider-r .hamburger-inner:before {
    top: 10px;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: .15s;
            transition-duration: .15s;
    -webkit-transition-property: opacity,-webkit-transform;
    transition-property: opacity,-webkit-transform;
    transition-property: transform,opacity;
    transition-property: transform,opacity,-webkit-transform
}

.hamburger--slider-r .hamburger-inner:after {
    top: 20px
}

.hamburger--slider-r.is-active .hamburger-inner {
    -webkit-transform: translate3d(0,10px,0) rotate(-45deg);
            transform: translate3d(0,10px,0) rotate(-45deg)
}

.hamburger--slider-r.is-active .hamburger-inner:before {
    -webkit-transform: rotate(45deg) translate3d(5.71429px,-6px,0);
            transform: rotate(45deg) translate3d(5.71429px,-6px,0);
    opacity: 0
}

.hamburger--slider-r.is-active .hamburger-inner:after {
    -webkit-transform: translate3d(0,-20px,0) rotate(90deg);
            transform: translate3d(0,-20px,0) rotate(90deg)
}

.show-menu {
    visibility: visible;
}
body {
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
}

h1,h2,h3,h4,h5 {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}

h1 {
  margin-bottom: 1.5em;
}

h2 {
  font-size: 2em;
}

a {
  opacity: 1 !important;
}


button:hover, button:focus {
  opacity: 1;
  outline: none;
}

p:last-of-type {
  margin-bottom: 2em;
}

a {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.8;
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
  border: none;
  outline: none;
  box-shadow: none;
}

a:hover,
a:active,
a:focus {
    opacity: 1;
}

a.active {
  opacity: 0.8;
}
